import React from "react";
import { navigate } from "gatsby";

import Layout from "../../components/layout";
import { BACKEND_BASE_URL } from "../../constant/constants";
import {
  DistrictBSection,
  Dropdown,
  NewsEventSlider,
  PastoratesList,
  Seo,
} from "../../components";

const DistrictInfo = ({
  selected_district,
  district_info,
  districts_index,
  location,
}) => {
  const onChange = e => {
    navigate("/districts/" + e.target.value);
  };

  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Dropdown
              data={districts_index}
              selected={selected_district}
              onChange={onChange}
              sort={false}
            />
            <DistrictBSection data={district_info} title="Brief History2" />
            <PastoratesList
              allChurches={district_info.pastorates}
              location={location}
            />
            <NewsEventSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

const DistrictBySlug = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo
        title={serverData.district_info.name}
        description={serverData.district_info.markdown}
        image_url={serverData.district_info.image_url}
      />
      <DistrictInfo
        selected_district={serverData.district_info.slug}
        district_info={serverData.district_info}
        districts_index={serverData.districts_index}
        location={location}
      />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const district_info_res = await fetch(
      BACKEND_BASE_URL + "districts/" + context.params.slug
    );
    const districts_index_res = await fetch(BACKEND_BASE_URL + "districts");
    if (!district_info_res.ok) {
      throw new Error(`Response failed`);
    }
    if (!districts_index_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        district_info: await district_info_res.json(),
        districts_index: await districts_index_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default DistrictBySlug;
